<template>
  <div id="app">
    <!-- 视频组件 -->
    <dualVideoCom
      ref="dualVideoCom"
      :mainStreamUrl="mainStreamUrl"
      :subStreamUrl="subStreamUrl"
      :examOperationTest="examOperationTest"
      :showFace="showFace"
      @changeShowFace="(val)=>{
        showFace = val
      }"
    ></dualVideoCom>

    <el-row :gutter="5" id="markingVideoReviewTable">
      <el-col :span="12">
        <el-table
          v-loading="loading"
          stripe
          border
          ref="markingVideoTable"
          :data="leftOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="index"
            label="序号"
            show-overflow-tooltip
            :width="56"
          ></el-table-column>
          <el-table-column label="评分项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.operationQuestionName }}
              <el-tooltip
                v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                effect="dark"
                :content="scope.row.markSuggest.remark"
                placement="bottom"
              >
                <i
                  class="el-icon-warning-outline"
                  style="float: right; line-height: 23px; font-size: 18px"
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            label="分值"
            show-overflow-tooltip
            :width="61"
          ></el-table-column>
          <el-table-column
            label="操作"
            :min-width="
              (markRule == 1 && markStatus == 1) ||
              (markRule == 2 && markStatus == 2)
                ? 40
                : 15
            "
            v-for="item in examOperationTest.markTeacherRecords"
            :key="item.markRecordId"
          >
            <template slot-scope="scope">
              <el-row>
                <el-col
                  :span="16"
                  v-if="
                    (markRule == 1 && markStatus == 1) ||
                    (markRule == 2 && markStatus == 2)
                  "
                >
                  <div>
                    <el-button
                      v-throttle
                      size="small"
                      type="primary"
                      @click="fullMarking(scope.row)"
                      >满分</el-button
                    >
                    <el-button
                      v-throttle
                      size="small"
                      type="danger"
                      @click="zeroMarking(scope.row)"
                      >零分</el-button
                    >
                  </div>
                </el-col>
                <el-col
                  :span="
                    (markRule == 1 && markStatus == 1) ||
                    (markRule == 2 && markStatus == 2)
                      ? 6
                      : 24
                  "
                >
                  <div
                    v-for="test in item.operationAchievements"
                    :key="test.operationQuestionId"
                  >
                    <el-input
                      v-if="
                        test.operationQuestionId ==
                        scope.row.operationQuestionId
                      "
                      v-model="scope.row.inputScore"
                      style="width: 60px"
                      :disabled="
                        !(
                          (markRule == 1 && markStatus == 1) ||
                          (markRule == 2 && markStatus == 2)
                        )
                      "
                      @input="scoreInput(scope.row, scope.row.inputScore)"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="12">
        <el-table
          v-loading="loading"
          stripe
          border
          ref="markingVideoTable"
          :data="rightOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="index"
            label="序号"
            show-overflow-tooltip
            :width="56"
          ></el-table-column>
          <el-table-column label="评分项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.operationQuestionName }}
              <el-tooltip
                v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                effect="dark"
                :content="scope.row.markSuggest.remark"
                placement="bottom"
              >
                <i
                  class="el-icon-warning-outline"
                  style="float: right; line-height: 23px; font-size: 18px"
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            label="分值"
            show-overflow-tooltip
            :width="61"
          ></el-table-column>
          <el-table-column
            label="操作"
            :min-width="
              (markRule == 1 && markStatus == 1) ||
              (markRule == 2 && markStatus == 2)
                ? 40
                : 15
            "
            v-for="item in examOperationTest.markTeacherRecords"
            :key="item.markRecordId"
          >
            <template slot-scope="scope">
              <el-row>
                <el-col
                  :span="16"
                  v-if="
                    (markRule == 1 && markStatus == 1) ||
                    (markRule == 2 && markStatus == 2)
                  "
                >
                  <div>
                    <el-button
                      v-throttle
                      size="small"
                      type="primary"
                      @click="fullMarking(scope.row)"
                      >满分</el-button
                    >
                    <el-button
                      v-throttle
                      size="small"
                      type="danger"
                      @click="zeroMarking(scope.row)"
                      >零分</el-button
                    >
                  </div>
                </el-col>
                <el-col
                  :span="
                    (markRule == 1 && markStatus == 1) ||
                    (markRule == 2 && markStatus == 2)
                      ? 6
                      : 24
                  "
                >
                  <div
                    v-for="test in item.operationAchievements"
                    :key="test.operationQuestionId"
                  >
                    <el-input
                      v-if="
                        test.operationQuestionId ==
                        scope.row.operationQuestionId
                      "
                      v-model="scope.row.inputScore"
                      style="width: 60px"
                      :disabled="
                        !(
                          (markRule == 1 && markStatus == 1) ||
                          (markRule == 2 && markStatus == 2)
                        )
                      "
                      @input="scoreInput(scope.row, scope.row.inputScore)"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-row class="marking-video-footer">
      <span>
        考生得分：
        <span>{{ totalScore }}</span> 分
      </span>
      <div
        v-if="
          (markRule == 1 && markStatus == 1) ||
          (markRule == 2 && markStatus == 2)
        "
      >
        <el-button v-throttle size="medium" type="primary" @click="submitExit"
          >提交并退出</el-button
        >
        <el-button v-throttle size="medium" type="primary" @click="exit"
          >仅退出</el-button
        >
      </div>
      <div v-else>
        <el-button v-throttle size="medium" type="primary" @click="getNextTest"
          >下一份</el-button
        >
        <el-button v-throttle size="medium" type="primary" @click="exit"
          >退出</el-button
        >
      </div>
    </el-row>
  </div>
</template>
<script>
import dualVideoCom from '@/components/dualVideoCom.vue'
import { updateScoreMarkApi } from '@/api/marking/markingVideoFormal.js'
import {
  getPersonalRecordApi,
  getNextPersonalRecordApi,
} from '@/api/marking/markingVideoReview.js'
import { durationToTimeSting, isEmpty, throttle } from '@/utils/util'
export default {
  name: 'markingVideoReview',
  components: {
    dualVideoCom,
  },
  data() {
    return {
      mainStreamUrl: '',
      subStreamUrl: '',
      loading: false,
      //过渡属性值
      customTransition: '',
      customOpacity: 0.8,
      submitForm: {
        remark: '',
      },
      examId: '',
      markRecordId: '',
      markStatus: 0,
      markRule: 0,
      examOperationTest: {},
      operationQuestionList: [],
      leftOperationQuestionList: [],
      rightOperationQuestionList: [],
      totalScore: 0,
      nextPageIndex: 0,

      showFace: false
    }
  },
  mounted() {
    this.examId = this.$route.query.examId
    this.markRecordId = this.$route.query.markRecordId
    this.markStatus = this.$route.query.markStatus
    this.markRule = this.$route.query.markRule
    console.log(
      `examId:${this.examId},markRecordId:${this.markRecordId}, markStatus:${this.markStatus}`
    )
    // this.initPlayer(this.mainStreamUrl, this.subStreamUrl)
    this.init()
    // this.$nextTick(() => {
    //   this.progressWidth = Math.floor(
    //     this.$refs.markingHandBoxProgressBar.clientWidth
    //   )
    // })
  },
  beforeDestroy() {
    // this.destroyPlayer()
  },
  methods: {
    init() {
      //获取个人阅卷记录
      this.getPersonalRecord()
    },
    getPersonalRecord() {
      if (isEmpty(this.markRecordId)) {
        return
      }
      getPersonalRecordApi(this.markRecordId)
        .then((res) => {
          if (res.success) {
            this.renderData(res.data)
            // this.getExamineeVideo(res.data.examineeId)
            this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },

    renderData(data) {
      this.examOperationTest = {}
      this.examOperationTest = data
      if (this.examOperationTest.examName.length > 25) {
        this.examOperationTest.aliasExamName =
          this.examOperationTest.examName.substring(0, 25) + '...'
      } else {
        this.examOperationTest.aliasExamName = this.examOperationTest.examName
      }
      this.examOperationTest.examNameLen =
        this.examOperationTest.examName.length
      this.mainStreamUrl = this.$imageAddress(
        this.examOperationTest.topCameraStream0
      )
      if (!isEmpty(this.examOperationTest.frontCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      } else if (!isEmpty(this.examOperationTest.sideCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.sideCameraStream0
        )
      } else {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      }
      this.$nextTick(() => {
        // this.startPlayer(this.mainStreamUrl, this.subStreamUrl)
        this.$refs.dualVideoCom.initPlayer(
          this.mainStreamUrl,
          this.subStreamUrl
        )
        // // 调组件
        // this.$refs.dualVideoCom.startPlayer(
        //   this.mainStreamUrl,
        //   this.subStreamUrl
        // )
      })
      this.totalScore = 0
      this.operationQuestionList = []
      this.leftOperationQuestionList = []
      this.rightOperationQuestionList = []
      let listSize = this.examOperationTest.operationQuestions.length
      let splitPoint = Math.ceil(listSize / 2)
      this.examOperationTest.operationQuestions.forEach((item, index) => {
        this.examOperationTest.markTeacherRecords.forEach(
          (markTeacher, markTeacherIndex) => {
            markTeacher.operationAchievements.forEach(
              (operation, operationIndex) => {
                if (item.operationQuestionId == operation.operationQuestionId) {
                  this.$set(item, 'inputScore', operation.score)
                }
              }
            )
          }
        )
        item.index = index + 1
        this.operationQuestionList.push(item)
        if (!isEmpty(item.inputScore)) {
          this.totalScore += Number(item.inputScore)
        }
        if (index < splitPoint) {
          this.leftOperationQuestionList.push(item)
        } else {
          this.rightOperationQuestionList.push(item)
        }
      })
      this.totalScore = this.totalScore.toFixed(1)
    },

    // handleResultImg(listReturn) {
    //   if (listReturn) {
    //     let imgResult = []
    //     listReturn.forEach((item) => {
    //       if (!isEmpty(item)) {
    //         imgResult.push(this.$imageAddress(item))
    //       }
    //     })
    //     this.faceList = imgResult
    //   }
    // },

    download() {},
    fullMarking(row) {
      this.$set(row, 'inputScore', row.score)
      this.computeScore(row)
    },
    zeroMarking(row) {
      this.$set(row, 'inputScore', 0)
      this.computeScore(row)
    },
    scoreInput(row, val) {
      if (isEmpty(val)) {
        this.$set(row, 'inputScore', '')
        this.computeScore()
        return
      }
      val = val.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')
      if (val > row.score) {
        val = row.score
      }
      this.$set(row, 'inputScore', val)
      this.computeScore()
    },
    computeScore() {
      let totalScore = 0
      this.operationQuestionList.forEach((item) => {
        if (isEmpty(item)) {
          return
        }
        if (isEmpty(item.inputScore)) {
          return
        }
        totalScore += Number(item.inputScore)
      })
      this.totalScore = totalScore.toFixed(1)
    },
    checkScore() {
      let queryParam = this.getScoreMarkParam()
      try {
        queryParam.operationQuestions.forEach((item, index) => {
          if (isEmpty(item.score) || isNaN(item.score)) {
            throw new Error(`第${index + 1}行分数不能为空`)
          }
        })
        this.updateScoreMark()
      } catch (e) {
        this.msgWarning(e.message)
      }
    },
    //提交并退出
    submitExit() {
      this.checkScore()
    },
    updateScoreMark() {
      if (this.operationQuestionList.length <= 0) {
        this.msgError('暂无数据')
        return
      }
      let queryParam = this.getScoreMarkParam()
      updateScoreMarkApi(JSON.stringify(queryParam))
        .then((res) => {
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.$router.push({ name: 'markingDetails' })
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    getScoreMarkParam() {
      let operationQuestions = []
      this.operationQuestionList.forEach((item) => {
        let operationQuestion = {
          operationQuestionId: 0,
          operationQuestionName: '',
          score: '',
          sortNum: 0,
        }
        operationQuestion.operationQuestionId = item.operationQuestionId
        operationQuestion.operationQuestionName = item.operationQuestionName
        if (!isEmpty(item.inputScore)) {
          operationQuestion.score = Number(item.inputScore)
        }
        operationQuestion.sortNum = item.sortNum
        operationQuestions.push(operationQuestion)
      })
      let queryParam = {
        examId: this.examOperationTest.examId,
        examineeId: this.examOperationTest.examineeId,
        operationQuestions: operationQuestions,
        operationTestId: this.examOperationTest.operationTestId,
        channel: 0,
      }
      return queryParam
    },
    //下一份
    getNextTest: throttle(function () {
      this.getNextPersonalRecord()
    }, 3000),
    getNextPersonalRecord() {
      if (isEmpty(this.examId)) {
        return
      }
      getNextPersonalRecordApi(
        this.nextPageIndex,
        this.markRecordId,
        this.examId
      ).then((res) => {
        if (res.success) {
          this.renderData(res.data)
        } else {
          let msg = ''
          switch (res.code) {
            case '821':
              msg = '已经是最后一份了'
              break
          }
          this.$confirm(msg, '系统提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {})
            .catch(() => {})
        }
      })
      this.nextPageIndex++
    },
    //仅退出
    exit() {
      this.globalMessageBox(
        `退出后不保留评卷记录，
      确定退出吗？`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          this.$router.push({ name: 'markingDetails' })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
#app {
  padding: 0 5px;
  margin-top: 2px;
}
::v-deep .el-card__body {
  padding: 15px;
}

#exam-info-box {
  width: 100%;
  height: 50px;
  line-height: 50px;
  /*   position: absolute;
  top: 0; */
  background-color: #409EFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 200;
}

#marking-hand-box {
  width: 100%;
  height: 55px;
  /* position: absolute;
  bottom: 0; */
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;
}

#marking-hand-box > img {
  margin-right: 10px;
  cursor: pointer;
}

.exam-info-badge {
  font: normal bolder 16px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #fff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marking-video-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marking-video-footer > span {
  color: #303133;
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
}

.marking-video-footer > span > span {
  color: #409EFF;
}

::v-deep .el-message-box > .el-message-box__btns > {
  text-align: center;
}

::v-deep .el-input__inner {
  height: 32px;
  text-align: center;
}

.marking-hand-box-progress-bar {
  width: 580px;
  height: 10px;
  line-height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 8px;
  position: relative;
  z-index: 0;
  background: #409EFF;
  cursor: pointer;
}

#markingVideoReviewTable ::v-deep .el-table .el-table__cell {
  padding: 6px 0;
}

.pointFrameImg {
  width: 100%;
  height: 100%;
  border: 1px solid #cbd8f5;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
