var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('dualVideoCom',{ref:"dualVideoCom",attrs:{"mainStreamUrl":_vm.mainStreamUrl,"subStreamUrl":_vm.subStreamUrl,"examOperationTest":_vm.examOperationTest,"showFace":_vm.showFace},on:{"changeShowFace":(val)=>{
      _vm.showFace = val
    }}}),_c('el-row',{attrs:{"gutter":5,"id":"markingVideoReviewTable"}},[_c('el-col',{attrs:{"span":12}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"markingVideoTable",staticStyle:{"width":"100%"},attrs:{"stripe":"","border":"","data":_vm.leftOperationQuestionList,"tooltip-effect":"dark","header-cell-style":{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"prop":"index","label":"序号","show-overflow-tooltip":"","width":56}}),_c('el-table-column',{attrs:{"label":"评分项","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.operationQuestionName)+" "),(scope.row.markSuggest && scope.row.markSuggest.remark)?_c('el-tooltip',{attrs:{"effect":"dark","content":scope.row.markSuggest.remark,"placement":"bottom"}},[_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"float":"right","line-height":"23px","font-size":"18px"}})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"score","label":"分值","show-overflow-tooltip":"","width":61}}),_vm._l((_vm.examOperationTest.markTeacherRecords),function(item){return _c('el-table-column',{key:item.markRecordId,attrs:{"label":"操作","min-width":(_vm.markRule == 1 && _vm.markStatus == 1) ||
            (_vm.markRule == 2 && _vm.markStatus == 2)
              ? 40
              : 15},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-row',[(
                  (_vm.markRule == 1 && _vm.markStatus == 1) ||
                  (_vm.markRule == 2 && _vm.markStatus == 2)
                )?_c('el-col',{attrs:{"span":16}},[_c('div',[_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.fullMarking(scope.row)}}},[_vm._v("满分")]),_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.zeroMarking(scope.row)}}},[_vm._v("零分")])],1)]):_vm._e(),_c('el-col',{attrs:{"span":(_vm.markRule == 1 && _vm.markStatus == 1) ||
                  (_vm.markRule == 2 && _vm.markStatus == 2)
                    ? 6
                    : 24}},_vm._l((item.operationAchievements),function(test){return _c('div',{key:test.operationQuestionId},[(
                      test.operationQuestionId ==
                      scope.row.operationQuestionId
                    )?_c('el-input',{staticStyle:{"width":"60px"},attrs:{"disabled":!(
                        (_vm.markRule == 1 && _vm.markStatus == 1) ||
                        (_vm.markRule == 2 && _vm.markStatus == 2)
                      )},on:{"input":function($event){return _vm.scoreInput(scope.row, scope.row.inputScore)}},model:{value:(scope.row.inputScore),callback:function ($$v) {_vm.$set(scope.row, "inputScore", $$v)},expression:"scope.row.inputScore"}}):_vm._e()],1)}),0)],1)]}}],null,true)})})],2)],1),_c('el-col',{attrs:{"span":12}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"markingVideoTable",staticStyle:{"width":"100%"},attrs:{"stripe":"","border":"","data":_vm.rightOperationQuestionList,"tooltip-effect":"dark","header-cell-style":{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"prop":"index","label":"序号","show-overflow-tooltip":"","width":56}}),_c('el-table-column',{attrs:{"label":"评分项","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.operationQuestionName)+" "),(scope.row.markSuggest && scope.row.markSuggest.remark)?_c('el-tooltip',{attrs:{"effect":"dark","content":scope.row.markSuggest.remark,"placement":"bottom"}},[_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"float":"right","line-height":"23px","font-size":"18px"}})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"score","label":"分值","show-overflow-tooltip":"","width":61}}),_vm._l((_vm.examOperationTest.markTeacherRecords),function(item){return _c('el-table-column',{key:item.markRecordId,attrs:{"label":"操作","min-width":(_vm.markRule == 1 && _vm.markStatus == 1) ||
            (_vm.markRule == 2 && _vm.markStatus == 2)
              ? 40
              : 15},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-row',[(
                  (_vm.markRule == 1 && _vm.markStatus == 1) ||
                  (_vm.markRule == 2 && _vm.markStatus == 2)
                )?_c('el-col',{attrs:{"span":16}},[_c('div',[_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.fullMarking(scope.row)}}},[_vm._v("满分")]),_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.zeroMarking(scope.row)}}},[_vm._v("零分")])],1)]):_vm._e(),_c('el-col',{attrs:{"span":(_vm.markRule == 1 && _vm.markStatus == 1) ||
                  (_vm.markRule == 2 && _vm.markStatus == 2)
                    ? 6
                    : 24}},_vm._l((item.operationAchievements),function(test){return _c('div',{key:test.operationQuestionId},[(
                      test.operationQuestionId ==
                      scope.row.operationQuestionId
                    )?_c('el-input',{staticStyle:{"width":"60px"},attrs:{"disabled":!(
                        (_vm.markRule == 1 && _vm.markStatus == 1) ||
                        (_vm.markRule == 2 && _vm.markStatus == 2)
                      )},on:{"input":function($event){return _vm.scoreInput(scope.row, scope.row.inputScore)}},model:{value:(scope.row.inputScore),callback:function ($$v) {_vm.$set(scope.row, "inputScore", $$v)},expression:"scope.row.inputScore"}}):_vm._e()],1)}),0)],1)]}}],null,true)})})],2)],1)],1),_c('el-row',{staticClass:"marking-video-footer"},[_c('span',[_vm._v(" 考生得分： "),_c('span',[_vm._v(_vm._s(_vm.totalScore))]),_vm._v(" 分 ")]),(
        (_vm.markRule == 1 && _vm.markStatus == 1) ||
        (_vm.markRule == 2 && _vm.markStatus == 2)
      )?_c('div',[_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"size":"medium","type":"primary"},on:{"click":_vm.submitExit}},[_vm._v("提交并退出")]),_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"size":"medium","type":"primary"},on:{"click":_vm.exit}},[_vm._v("仅退出")])],1):_c('div',[_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"size":"medium","type":"primary"},on:{"click":_vm.getNextTest}},[_vm._v("下一份")]),_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"size":"medium","type":"primary"},on:{"click":_vm.exit}},[_vm._v("退出")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }