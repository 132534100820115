import { request } from '@/utils/request.js'

//获取个人阅卷记录
export function getPersonalRecordApi (markRecordId) {
  return request({
    url: `/exam/score/${markRecordId}/record/get`,
    method: 'post',
  })
}

//查看下一份个人阅卷记录(pageIndex从0++）
export function getNextPersonalRecordApi (pageIndex, markRecordId, examId) {
  return request({
    url: `/exam/score/next/${pageIndex}/${markRecordId}/${examId}/reocrd/get`,
    method: 'post',
  })
}
